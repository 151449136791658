import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import 'react-native-gesture-handler';
import useComponentSize from '../hooks/useComponentSize';
import Renderer from './Renderer';
import { defaultDesignSettings, defaultGenerateSettings, DesignSettings, GenerateSettings } from './Settings';
import Editor from './Editor';
import { Button, Icon, Layout, Text } from '@ui-kitten/components';
import AsyncStorage from '@react-native-community/async-storage';

export default function Main() {
  const [size, onLayout] = useComponentSize();
  const [page, setPage] = React.useState<number>(0);
  const [capture, setCapture] = React.useState<boolean>(false);
  const [generate, setGenerate] = React.useState<GenerateSettings>(defaultGenerateSettings);
  const [design, setDesign] = React.useState<DesignSettings>();
  const [renderPreview, setRenderPreview] = React.useState(false);

  async function fetchDesign() {
    if(!design) setDesign(Object.assign({}, defaultDesignSettings, JSON.parse(await AsyncStorage.getItem("generator:design_settings") || "{}")));
  }
  async function saveDesign() {
    if(design) await AsyncStorage.setItem("generator:design_settings", JSON.stringify(design));
  }

  React.useEffect(()=>{fetchDesign()});
  React.useEffect(()=>{saveDesign()}, [design]);

  if (!size || !design) {
    return <View style={{ flex: 1 }} onLayout={onLayout}><Layout onLayout={onLayout} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}><ActivityIndicator color="#ffffff" size={48} /><Text style={{ fontSize: 24 }}>Loading</Text></Layout></View>;
  }
  return (
    <View onLayout={onLayout} style={{ flexDirection: size.width < 720 ? "column" : "row", flex: 1 }}>
      {renderPreview ? <View style={size.width < 720 ? { height: 300, maxHeight: "30vh" } : { flex: 1 }}>
        <Renderer renderMe={true} design={design} generate={generate} capture={capture} setCapture={setCapture} page={page} setPage={setPage} />
        <View style={{ position: "absolute", top: 8, left: 8 }}>
          <Button status="primary" accessoryLeft={props=><Icon name="arrow-back-outline" {...props} />} onPress={()=>setPage(page-1)} />
        </View>
        <View style={{ position: "absolute", top: 8, right: 8 }}>
          <Button status="primary" accessoryLeft={props=><Icon name="arrow-forward-outline" {...props} />} onPress={()=>setPage(page+1)} />
        </View>
      </View> : <Renderer renderMe={false} design={design} generate={generate} capture={capture} setCapture={setCapture} page={page} setPage={setPage} />}
      <View style={(size.width < 720 || !renderPreview) ? { flex: 1 } : { width: 400 }}>
        <Editor renderPreview={renderPreview} setRenderPreview={setRenderPreview} design={design} setDesign={setDesign} generate={generate} setGenerate={setGenerate} capture={()=>setCapture(true)} />
      </View>
    </View>
  );
}
