import { GLView } from "expo-gl";
// @ts-ignore
import { PIXI } from 'expo-pixi';
import React, { useMemo, useState } from "react";
import Color from "color";
import useComponentSize from "../hooks/useComponentSize";
import { DesignSettings, GenerateSettings, GenerateTypes } from "./Settings";
import JSZip from "jszip";
import saveAs from './save';
import { Layout } from "@ui-kitten/components";

function randomColourGenerator() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

type Block = {
  colour: string;
  render?: {
    width: number;
    height: number;
  } | null;
};

function generateGrid(
  width: number,
  height: number,
  maxSize: number,
  squares?: boolean,
  getRandomColour?: (x: number, y: number) => string
) {
  const grid = new Map<string, Block>();
  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      if (!grid.has(`${x}:${y}`)) {
        const colour = (getRandomColour || randomColourGenerator)(x, y);
        let widthMin = Math.random() > 0.5;
        let width = Math.ceil(Math.random() * maxSize);
        let height = squares ? width : Math.ceil(Math.random() * maxSize);
        for (let x2 = 0; x2 < width; x2++) {
          for (let y2 = 0; y2 < height; y2++) {
            if (grid.has(`${x + x2}:${y + y2}`)) {
              if (squares) {
                height = width = Math.max(1, Math.min(width, y2));
              } else if (y2 === 0 && widthMin) {
                width = Math.max(1, Math.min(width, x2));
              } else if (x2 === 0 || !widthMin) {
                height = Math.max(1, Math.min(height, y2));
              } else {
                width = Math.max(1, Math.min(width, x2));
              }
            }
          }
        }
        for (let x2 = 0; x2 < width; x2++) {
          for (let y2 = 0; y2 < height; y2++) {
            grid.set(`${x + x2}:${y + y2}`, {
              colour,
              render:
                x2 === 0 && y2 === 0
                  ? {
                      width,
                      height,
                    }
                  : null,
            });
          }
        }
      }
    }
  }
  return grid;
}

type RendererProps = {
  generate: GenerateSettings;
  design: DesignSettings;
  capture?: boolean;
  setCapture: (capture: boolean) => void;
  page: number;
  setPage: (page: number) => void;
  renderMe: boolean;
};

type RendererData = {
  ratio: number;
  heightMinimum: boolean;
  width: number;
  height: number;
  scale: number;
  grid: Map<string, Block>;
  gridSize: number;
  maxSize: number;
  generate: GenerateSettings;
  design: DesignSettings;
  noResize?: boolean;
};

function renderApp(
  app: PIXI.Application,
  data: RendererData,
  d: number,
  showSolutions: boolean
) {
  const {
    width,
    height,
    scale,
    grid,
    gridSize,
    maxSize,
    generate,
    design,
    noResize,
  } = data;
  app.renderer.resize(width, height);
  app.renderer.backgroundColor = Color.hsv({
    h: design.backdrop_hue,
    s: design.backdrop_saturation,
    v: design.backdrop_value,
  }).rgbNumber();
  const boxColour = Color.hsv({
    h: design.box_hue,
    s: design.box_saturation,
    v: design.box_value,
  }).rgbNumber();
  const boxOpacity = design.box_opacity / 100;
  const day = generate.days[d];

  app.stage.children = [];

  // Background
  const background = new PIXI.Graphics();

  const squareSize = Math.max(width / gridSize, height / gridSize);

  for (let x = -maxSize; x < gridSize; x++) {
    for (let y = -maxSize; y < gridSize; y++) {
      let value = grid.get(`${x + 5}:${y + 5}`);
      if (!value) continue;
      if (value.render) {
        background.beginFill(parseInt(value.colour.slice(1), 16));
        background.drawRect(
          x * squareSize + squareSize * 0.1,
          y * squareSize + squareSize * 0.1,
          squareSize * value.render.width - squareSize * 0.2,
          squareSize * value.render.height - squareSize * 0.2
        );
        background.endFill();
      }
    }
  }
  app.stage.addChild(background);

  // Type Label
  const labelBackground = new PIXI.Graphics();
  app.stage.addChild(labelBackground);

  const l = new PIXI.Text("wi".repeat(20));
  l.style = new PIXI.TextStyle({
    fontSize: 32,
    fill: "#ffffff",
    fontFamily: "serif",
  });

  const x = new PIXI.Text("wi".repeat(20));
  x.style = new PIXI.TextStyle({
    fontSize: 32,
    fill: "#ffffff",
    fontFamily: "Rubik_700Bold, serif",
  });
  if (l.width === x.width) {
    labelBackground.beginFill(0);
    labelBackground.drawRect(0, 0, width * 2, height * 2);
    labelBackground.endFill();
    setTimeout(() => {
      renderApp(app, data, d, showSolutions);
    }, 500);
    return;
  }

  const label = new PIXI.Text(
    generate.title ||
      GenerateTypes.find((i) => i.id === generate.type)?.public_title ||
      GenerateTypes.find((i) => i.id === generate.type)?.title
  );
  label.style = new PIXI.TextStyle({
    fontSize: 32 * scale,
    fill: "#ffffff",
    fontFamily: "Rubik_700Bold",
  });
  label.x = (width - label.width) / 2;
  label.y = 12 * scale;
  labelBackground.beginFill(boxColour, boxOpacity);
  labelBackground.drawRoundedRect(
    label.x - 12 * scale,
    label.y - 24 * scale,
    label.width + 24 * scale,
    label.height + 36 * scale,
    8 * scale
  );
  labelBackground.endFill();
  app.stage.addChild(label);

  // Date Label
  const dateBackground = new PIXI.Graphics();
  app.stage.addChild(dateBackground);

  const date = new PIXI.Text(day.date.toLocaleDateString("en-GB"));
  date.style = new PIXI.TextStyle({
    fontSize: 32 * scale,
    fill: "#ffffff",
    fontFamily: "Rubik_700Bold",
  });
  date.x = 12 * scale;
  date.y = height - date.height - 12 * scale;
  dateBackground.beginFill(boxColour, boxOpacity);
  dateBackground.drawRoundedRect(
    date.x - 24 * scale,
    date.y - 12 * scale,
    date.width + 36 * scale,
    date.height + 36 * scale,
    8 * scale
  );
  dateBackground.endFill();
  app.stage.addChild(date);

  // Credits Label
  const creditsBackground = new PIXI.Graphics();
  app.stage.addChild(creditsBackground);

  const credits = new PIXI.Text(
    "Generated by reverseenumerate.sohcah.dev\nSite by Sam Hindess" +
      (design.credits ? `\n${design.credits}` : "")
  );
  credits.style = new PIXI.TextStyle({
    fontSize: 16 * scale,
    fill: "#ffffff",
    fontFamily: "Rubik_300Light",
    align: "right",
  });
  credits.x = width - credits.width - 8 * scale;
  credits.y = height - credits.height - 8 * scale;
  creditsBackground.beginFill(boxColour, boxOpacity);
  creditsBackground.drawRoundedRect(
    credits.x - 8 * scale,
    credits.y - 8 * scale,
    credits.width + 24 * scale,
    credits.height + 24 * scale,
    8 * scale
  );
  creditsBackground.endFill();
  app.stage.addChild(credits);

  let offset = (label.y + label.height) / scale + 24;

  // Teaser Text
  if (generate.type === "teaser" && day.clue) {
    const teaserBackground = new PIXI.Graphics();
    app.stage.addChild(teaserBackground);

    const teaser = new PIXI.Text(day.clue);
    teaser.style = new PIXI.TextStyle({
      fontSize: 32 * scale,
      fill: "#ffffff",
      fontFamily: "Rubik_700Bold",
      wordWrapWidth: 900 * scale,
      wordWrap: true,
      align: "center",
    });
    teaser.x = (width - teaser.width) / 2;
    teaser.y = offset * scale + 12;
    // Math.max(offset * scale, 200 * scale - teaser.height)
    teaserBackground.beginFill(boxColour, boxOpacity);
    teaserBackground.drawRoundedRect(
      teaser.x - 12 * scale,
      teaser.y - 12 * scale,
      teaser.width + 24 * scale,
      teaser.height + 24 * scale,
      8 * scale
    );
    teaserBackground.endFill();
    app.stage.addChild(teaser);
    offset = (teaser.y + teaser.height) / scale + 24;
  }

  // Tiles
  const tiles = new PIXI.Graphics();
  app.stage.addChild(tiles);

  const generate_letters = day.letters.split("");
  // for (let letter of (day.solutions[n - 1] || "").split("")) {
  //   generate_letters[generate_letters.indexOf(letter)] = "-";
  // }

  const tileSize = Math.min(100, 900 / day.letters.length);
  const tilePadding = tileSize * 0.05;
  const tileY = offset;
  const tileWidth = tileSize - 2 * tilePadding;
  const tileHeight = tileSize - 2 * tilePadding;

  for (let i = 0; i < generate_letters.length; i++) {
    const position = (i - generate_letters.length / 2) * tileSize;

    const tileX = position + tilePadding + 470;

    tiles.beginFill(
      boxColour,
      (generate_letters[i] === " "
        ? 0
        : generate_letters[i] === "-"
        ? 0.7
        : 1) * boxOpacity
    );
    tiles.drawRoundedRect(
      tileX * scale,
      tileY * scale,
      tileWidth * scale,
      tileHeight * scale,
      tilePadding * scale
    );
    tiles.endFill();

    const letter = new PIXI.Text(
      generate_letters[i] === "-" ? " " : generate_letters[i]
    );
    letter.style = new PIXI.TextStyle({
      fontSize: tileSize * 0.8 * scale,
      fill: "#ffffff",
      fontFamily: "Rubik_700Bold",
    });
    letter.x = (tileX + tileWidth / 2) * scale - letter.width / 2;
    letter.y = (tileY + tileHeight / 2) * scale - letter.height / 2;
    app.stage.addChild(letter);
  }
  offset = tileY + tileHeight + 12;

  if (showSolutions) {
    
    const solutionBackground = new PIXI.Graphics();
    app.stage.addChild(solutionBackground);

    const solutionText = new PIXI.Text(
      `${(generate.type === "letters_bonus" || generate.type === "letters") ? "Best Word" : "Solution"}${day.solutions.length === 1 ? "" : "s"}`
    );
    solutionText.style = new PIXI.TextStyle({
      fontSize: 32 * scale,
      fill: "#ffffff",
      fontFamily: "Rubik_700Bold",
      wordWrapWidth: 900 * scale,
      wordWrap: true,
      align: "center",
    });
    solutionText.x = (width - solutionText.width) / 2;
    solutionText.y = offset * scale + 12 * scale;
    solutionBackground.beginFill(boxColour, boxOpacity);
    solutionBackground.drawRoundedRect(
      solutionText.x - 12 * scale,
      solutionText.y - 12 * scale,
      solutionText.width + 24 * scale,
      solutionText.height + 24 * scale,
      8 * scale
    );
    solutionBackground.endFill();
    app.stage.addChild(solutionText);
    offset = (solutionText.y + solutionText.height) / scale + 24;
    for (let solution of day.solutions) {
      // Solution Tiles
      const solutionTiles = new PIXI.Graphics();
      app.stage.addChild(solutionTiles);

      const solutionTileSize = Math.min(75, 900 / solution.length);
      const solutionTilePadding = solutionTileSize * 0.05;
      const solutionTileY = offset;
      const solutionTileWidth = solutionTileSize - 2 * solutionTilePadding;
      const solutionTileHeight = solutionTileSize - 2 * solutionTilePadding;

      for (let i = 0; i < solution.length; i++) {
        const position = (i - solution.length / 2) * solutionTileSize;

        const solutionTileX = position + solutionTilePadding + 470;

        solutionTiles.beginFill(
          boxColour,
          (solution[i] === " " ? 0 : solution[i] === "-" ? 0.7 : 1) * boxOpacity
        );
        solutionTiles.drawRoundedRect(
          solutionTileX * scale,
          solutionTileY * scale,
          solutionTileWidth * scale,
          solutionTileHeight * scale,
          solutionTilePadding * scale
        );
        solutionTiles.endFill();

        const letter = new PIXI.Text(solution[i]);
        letter.style = new PIXI.TextStyle({
          fontSize: solutionTileSize * 0.8 * scale,
          fill: "#ffffff",
          fontFamily: "Rubik_700Bold",
        });
        letter.x =
          (solutionTileX + solutionTileWidth / 2) * scale - letter.width / 2;
        letter.y =
          (solutionTileY + solutionTileHeight / 2) * scale - letter.height / 2;
        app.stage.addChild(letter);
      }
      offset = solutionTileY + solutionTileHeight + 12;
    }
    
    solutionBackground.beginFill(boxColour, boxOpacity * 0.4);
    solutionBackground.drawRoundedRect(
      (width - (908 * scale)) / 2,
      solutionText.y - 16 * scale,
      908 * scale,
      ((offset - 4) * scale) - (solutionText.y - 16 * scale),
      8 * scale
    );
    solutionBackground.endFill();

    offset += 8
  }

  // Bonus Text
  if (generate.type === "letters_bonus" && day.clue) {
    const bonusBackground = new PIXI.Graphics();
    app.stage.addChild(bonusBackground);

    const bonus1 = new PIXI.Text(
      `Play as a normal letters round or/and play the...`
    );
    bonus1.style = new PIXI.TextStyle({
      fontSize: 24 * scale,
      fill: "#ffffff",
      fontFamily: "Rubik_300Light",
      wordWrapWidth: 900 * scale,
      wordWrap: true,
      align: "center",
    });
    bonus1.x = (width - bonus1.width) / 2;
    bonus1.y = offset * scale + 12 * scale;
    const bonus2 = new PIXI.Text(
      `Bonus Game (all ${day.letters.split('').filter(i=>i!==" ").length} letters)`
    );
    bonus2.style = new PIXI.TextStyle({
      fontSize: 32 * scale,
      fill: "#ffffff",
      fontFamily: "Rubik_700Bold",
      wordWrapWidth: 900 * scale,
      wordWrap: true,
      align: "center",
    });
    bonus2.x = (width - bonus2.width) / 2;
    bonus2.y = bonus1.y + bonus1.height + 4;
    const bonus = new PIXI.Text(day.clue);
    bonus.style = new PIXI.TextStyle({
      fontSize: 28 * scale,
      fill: "#ffffff",
      fontFamily: "Rubik_700Bold",
      wordWrapWidth: 900 * scale,
      wordWrap: true,
      align: "center",
    });
    bonus.x = (width - bonus.width) / 2;
    bonus.y = bonus2.y + bonus2.height + 4;
    bonusBackground.beginFill(boxColour, boxOpacity);
    bonusBackground.drawRoundedRect(
      Math.min(bonus1.x, bonus2.x, bonus.x) - 12 * scale,
      bonus1.y - 12 * scale,
      Math.max(bonus1.width, bonus2.width, bonus.width) + 24 * scale,
      bonus1.height + 4 + bonus2.height + 4 + bonus.height + 24 * scale,
      8 * scale
    );
    bonusBackground.endFill();
    app.stage.addChild(bonus1);
    app.stage.addChild(bonus2);
    app.stage.addChild(bonus);
    offset = (bonus.y + bonus.height) / scale + 24;
    // Solution Tiles
    const solutionTiles = new PIXI.Graphics();
    app.stage.addChild(solutionTiles);

    const solutionTileSize = Math.min(75, 900 / day.bonus_solution.length);
    const solutionTilePadding = solutionTileSize * 0.05;
    const solutionTileY = offset;
    const solutionTileWidth = solutionTileSize - 2 * solutionTilePadding;
    const solutionTileHeight = solutionTileSize - 2 * solutionTilePadding;

    if (showSolutions) {
      for (let i = 0; i < day.bonus_solution.length; i++) {
        const position = (i - day.bonus_solution.length / 2) * solutionTileSize;

        const solutionTileX = position + solutionTilePadding + 470;

        solutionTiles.beginFill(
          boxColour,
          (day.bonus_solution[i] === " "
            ? 0
            : day.bonus_solution[i] === "-"
            ? 0.7
            : 1) * boxOpacity
        );
        solutionTiles.drawRoundedRect(
          solutionTileX * scale,
          solutionTileY * scale,
          solutionTileWidth * scale,
          solutionTileHeight * scale,
          solutionTilePadding * scale
        );
        solutionTiles.endFill();

        const letter = new PIXI.Text(day.bonus_solution[i]);
        letter.style = new PIXI.TextStyle({
          fontSize: solutionTileSize * 0.8 * scale,
          fill: "#ffffff",
          fontFamily: "Rubik_700Bold",
        });
        letter.x =
          (solutionTileX + solutionTileWidth / 2) * scale - letter.width / 2;
        letter.y =
          (solutionTileY + solutionTileHeight / 2) * scale - letter.height / 2;
        app.stage.addChild(letter);
      }
      offset = solutionTileY + solutionTileHeight + 12;

      bonusBackground.beginFill(boxColour, boxOpacity * 0.4);
      bonusBackground.drawRoundedRect(
        (width - (908 * scale)) / 2,
        bonus1.y - 16 * scale,
        908 * scale,
        ((offset - 4) * scale) - (bonus1.y - 16 * scale),
        8 * scale
      );
      bonusBackground.endFill();
    }
  }
}

export default function Renderer({
  design,
  generate,
  capture,
  setCapture,
  page,
  setPage,
  renderMe,
}: RendererProps) {
  const [size, onLayout] = useComponentSize();

  const gridSize = design.grid_size;
  const maxSize = design.tile_size;
  const grid = useMemo(
    () =>
      generateGrid(
        gridSize + 2 * maxSize, // Width
        gridSize + 2 * maxSize, // Height
        maxSize, // Maximum Size
        design.squares, // Squares
        (x: number, y: number) => {
          // Colour Generator
          return Color.hsv({
            h:
              design.squares_hue + design.squares_hue_variation * Math.random(),
            s:
              design.squares_saturation +
              design.squares_saturation_variation * Math.random(),
            v:
              design.squares_value +
              design.squares_value_variation * Math.random(),
          }).hex();
        }
      ),
    [design]
  );

  const [application, setApplication] = useState<PIXI.Application | null>(null);

  const pages = [
    ...generate.days.reduce<{ i: number; n: boolean }[]>(
      (a, _, i) => [
        ...a,
        { i, n: false },
        { i, n: true },
      ],
      []
    ),
    { i: 0, n: false },
  ];
  console.log(pages);

  let ratio = 940 / 788;
  let heightMinimum: boolean =
    (size?.width || 0) / 940 > (size?.height || 0) / 788;
  let width: number = heightMinimum
    ? (size?.height || 0) * ratio
    : size?.width || 0;
  let height: number = heightMinimum
    ? size?.height || 0
    : (size?.width || 0) / ratio;
  let scale: number = width / 940;

  if (capture) {
    width = 940;
    height = 788;
    scale = 1;
  }

  React.useEffect(() => {
    if (application)
      renderApp(
        application,
        {
          ratio,
          heightMinimum,
          width,
          height,
          scale,
          grid,
          gridSize,
          maxSize,
          generate,
          design,
        },
        pages[page].i,
        pages[page].n
      );
    if (page >= pages.length - 1) {
      setPage(0);
    }
  }, [size, generate, design, page]);

  React.useEffect(() => {
    if (capture) {
      generateScreenshot();
      setCapture(false);
    }
  }, [capture]);

  async function generateScreenshot() {
    var zip = new JSZip();
    const context = await GLView.createContextAsync();
    const app = new PIXI.Application({
      context,
      height: 940,
      width: 788,
      resolution: 1,
      backgroundColor: Color.hsv({
        h: design.backdrop_hue,
        s: design.backdrop_saturation,
        v: design.backdrop_value,
      }).rgbNumber(),
    });
    if (context.canvas) {
      context.canvas.width = 940;
      context.canvas.height = 788;
    }
    for (let page of pages.slice(0, -1)) {
      renderApp(
        app,
        {
          ratio: 940 / 788,
          heightMinimum: true,
          width: 940,
          height: 788,
          scale: 1,
          grid,
          gridSize,
          maxSize,
          generate,
          noResize: true,
          design,
        },
        page.i,
        page.n
      );
      app.render();
      const data = await GLView.takeSnapshotAsync(context, {
        format: "jpeg",
        rect: {
          x: 0,
          y: 0,
          width: 940,
          height: 788,
        },
      });
      const d = new Date(generate.days[page.i].date);
      const dateString =
        d.getFullYear().toString().padStart(4, "0") +
        "_" +
        d.getMonth().toString().padStart(2, "0") +
        "_" +
        d.getDate().toString().padStart(2, "0");
      if (data?.uri)
        zip.file(
          `${dateString}-${page.i + 1}-${
            page.n ? "Solution" : `Main`
          }.jpeg`,
          data?.uri
        );
    }
    const dStart = new Date(generate.days[0].date);
    const dateStartString =
      dStart.getFullYear().toString().padStart(4, "0") +
      "_" +
      dStart.getMonth().toString().padStart(2, "0") +
      "_" +
      dStart.getDate().toString().padStart(2, "0");
    const dEnd = new Date(generate.days[generate.days.length - 1].date);
    const dateEndString =
      dEnd.getFullYear().toString().padStart(4, "0") +
      "_" +
      dEnd.getMonth().toString().padStart(2, "0") +
      "_" +
      dEnd.getDate().toString().padStart(2, "0");
    zip.generateAsync({ type: "blob" }).then(function (content: any) {
      saveAs(
        content,
        `cdgraphics-${
          generate.title
            .toLowerCase()
            .replace(/\s/g, "_")
            .replace(/[^0-9a-z_]/g, "") || generate.type
        }-${dateStartString}-${dateEndString}.zip`
      );
      setCapture(false);
    });
  }

  if(!renderMe) return null;
  return (
    <Layout
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
      level="4"
      onLayout={onLayout}
    >
      {size && (
        <GLView
          style={{ height, width }}
          onContextCreate={async (context) => {
            const app = new PIXI.Application({
              context,
              resolution: window.devicePixelRatio,
              backgroundColor: Color.hsv({
                h: design.backdrop_hue,
                s: design.backdrop_saturation,
                v: design.backdrop_value,
              }).rgbNumber(),
            });
            setApplication(app);
            renderApp(
              app,
              {
                ratio,
                heightMinimum,
                width,
                height,
                scale,
                grid,
                gridSize,
                maxSize,
                generate,
                design,
              },
              pages[page].i,
              pages[page].n
            );
          }}
        />
      )}
    </Layout>
  );
}
