import React from "react";
import { ScrollView, View } from "react-native";
import {
  GenerateSettings,
  GenerateTypes,
  DesignSettings,
  defaultDesignSettings,
  halloweenDesignSettings,
  normalDesignSettings,
  christmasDesignSettings,
  GenerateDay,
  defaultGenerateDay,
  ParseGenerate,
} from "./Settings";
import {
  Button,
  IndexPath,
  Layout,
  Select,
  SelectItem,
  Icon,
  Input,
  Datepicker,
  Tab,
  TabView,
  Text,
  Toggle,
} from "@ui-kitten/components";
import { TouchableWithoutFeedback } from "@ui-kitten/components/devsupport";
import useComponentSize from "../hooks/useComponentSize";

type EditorProps = {
  generate: GenerateSettings;
  setGenerate: (generateSettings: GenerateSettings) => void;
  design: DesignSettings;
  setDesign: (designSettings: DesignSettings) => void;
  capture: () => void;
  renderPreview: boolean;
  setRenderPreview: (renderPreview: boolean) => void;
};

type GenerateEditorProps = {
  generate: GenerateSettings;
  setGenerate: (generateSettings: GenerateSettings) => void;
  capture: () => void;
  renderPreview: boolean;
  setRenderPreview: (renderPreview: boolean) => void;
};

type DesignEditorProps = {
  design: DesignSettings;
  setDesign: (designSettings: DesignSettings) => void;
};

export function DesignEditor({ design, setDesign }: DesignEditorProps) {
  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{ padding: 4, flexGrow: 1 }}
    >
      <Text style={{ fontWeight: "bold", marginLeft: 4 }}>Backdrop</Text>
      <View style={{ flexDirection: "row" }}>
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Hue"
          value={design.backdrop_hue.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              backdrop_hue: Number(text),
            })
          }
        />
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Saturation"
          value={design.backdrop_saturation.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              backdrop_saturation: Number(text),
            })
          }
        />
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Value"
          value={design.backdrop_value.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              backdrop_value: Number(text),
            })
          }
        />
      </View>
      <Text style={{ fontWeight: "bold", marginLeft: 4 }}>Backdrop Tiles</Text>
      <View style={{ flexDirection: "row" }}>
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Hue"
          value={design.squares_hue.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              squares_hue: Number(text),
            })
          }
        />
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Saturation"
          value={design.squares_saturation.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              squares_saturation: Number(text),
            })
          }
        />
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Value"
          value={design.squares_value.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              squares_value: Number(text),
            })
          }
        />
      </View>
      <View style={{ flexDirection: "row" }}>
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Hue Variation"
          value={design.squares_hue_variation.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              squares_hue_variation: Number(text),
            })
          }
        />
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Saturation Variation"
          value={design.squares_saturation_variation.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              squares_saturation_variation: Number(text),
            })
          }
        />
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Value Variation"
          value={design.squares_value_variation.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              squares_value_variation: Number(text),
            })
          }
        />
      </View>
      <Toggle
        checked={design.squares}
        onChange={() =>
          setDesign({
            ...design,
            squares: !design.squares,
          })
        }
      >
        Squares only
      </Toggle>
      <View style={{ flexDirection: "row" }}>
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Max Size"
          value={design.tile_size.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              tile_size: Number(text),
            })
          }
        />
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Grid Width"
          value={design.grid_size.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              grid_size: Number(text),
            })
          }
        />
      </View>
      <Text style={{ fontWeight: "bold", marginLeft: 4 }}>Boxes</Text>
      <View style={{ flexDirection: "row" }}>
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Hue"
          value={design.box_hue.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              box_hue: Number(text),
            })
          }
        />
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Saturation"
          value={design.box_saturation.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              box_saturation: Number(text),
            })
          }
        />
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Value"
          value={design.box_value.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              box_value: Number(text),
            })
          }
        />
        <Input
          style={{ margin: 4, flex: 1 }}
          label="Opacity"
          value={design.box_opacity.toString()}
          onChangeText={(text) =>
            setDesign({
              ...design,
              box_opacity: Number(text),
            })
          }
        />
      </View>
      <Text style={{ fontWeight: "bold", marginLeft: 4 }}>Others</Text>
      <Input
        style={{ margin: 4 }}
        label="Credits"
        value={design.credits}
        multiline={true}
        size="large"
        onChangeText={(text) =>
          setDesign({
            ...design,
            credits: text,
          })
        }
      />
      <Text style={{ fontWeight: "bold", marginLeft: 4 }}>Themes</Text>
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        <Button
          style={{ width: 100, flexGrow: 1 }}
          accessoryLeft={(props) => <Icon name="brush-outline" {...props} />}
          appearance="ghost"
          status="primary"
          onPress={() =>
            setDesign({
              ...design,
              ...normalDesignSettings,
            })
          }
        >
          Normal
        </Button>
        <Button
          style={{ width: 100, flexGrow: 1 }}
          accessoryLeft={(props) => <Icon name="brush-outline" {...props} />}
          appearance="ghost"
          status="warning"
          onPress={() =>
            setDesign({
              ...design,
              ...halloweenDesignSettings,
            })
          }
        >
          Halloween
        </Button>
        <Button
          style={{ width: 100, flexGrow: 1 }}
          accessoryLeft={(props) => <Icon name="brush-outline" {...props} />}
          appearance="ghost"
          status="success"
          onPress={() =>
            setDesign({
              ...design,
              ...christmasDesignSettings,
            })
          }
        >
          Christmas
        </Button>
      </View>
      <Button
        accessoryLeft={(props) => <Icon name="trash-outline" {...props} />}
        appearance="ghost"
        status="danger"
        onPress={() =>
          setDesign({
            ...defaultDesignSettings,
            credits: design.credits,
          })
        }
      >
        Reset Design Settings
      </Button>
    </ScrollView>
  );
}

export function GenerateEditor({
  generate,
  setGenerate,
  capture,
  renderPreview,
  setRenderPreview,
}: GenerateEditorProps) {
  const [importMenu, setImportMenu] = React.useState(false);
  const [importText, setImportText] = React.useState("");
  const [size, onLayout] = useComponentSize();
  if(importMenu) {
    return <View style={{ flex: 1, padding: 4 }}>
      <View onLayout={onLayout} style={{ flex: 1 }}>
        <Input
          style={{ margin: 4 }}
          textStyle={{ minHeight: (size?.height || 0) - 24 }}
          value={importText}
          onChangeText={(text) =>
            setImportText(text)
          }
          multiline={true}
        />
      </View>
      <Button
        style={{ margin: 4 }}
        accessoryLeft={(props) => <Icon name="download-outline" {...props} />}
        onPress={() => {
          setGenerate(ParseGenerate(importText))
          setImportText("");
          setImportMenu(false);
        }}
      >
        Import Data
      </Button>
    </View>
  }
  return (
    <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1, padding: 4 }}>
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        <Select
          style={{ margin: 4, maxWidth: "100%", width: 400, flexGrow: 1 }}
          label="Type"
          value={
            GenerateTypes.find((i) => i.id === generate.type)?.title || "N/A"
          }
          selectedIndex={
            new IndexPath(
              GenerateTypes.findIndex((i) => i.id === generate.type)
            )
          }
          onSelect={(index) =>
            setGenerate({
              ...generate,
              type: GenerateTypes[(index as IndexPath).row].id,
            })
          }
        >
          {GenerateTypes.map((i) => (
            <SelectItem title={i.title} />
          ))}
        </Select>
        <Input
          style={{ margin: 4, maxWidth: "100%", width: 400, flexGrow: 1 }}
          label="Header Title"
          value={generate.title}
          placeholder={GenerateTypes.find((i) => i.id === generate.type)?.title}
          onChangeText={(text) =>
            setGenerate({
              ...generate,
              title: text,
            })
          }
        />
      </View>
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        {generate.days.map((day, dayIndex) => {
          const setDay = (value: GenerateDay) => {
            setGenerate({
              ...generate,
              days: [
                ...generate.days.slice(0, dayIndex),
                value,
                ...generate.days.slice(dayIndex + 1),
              ],
            });
          };
          return (
            <Layout
              level="3"
              style={{
                padding: 4,
                margin: 4,
                borderRadius: 4,
                maxWidth: "100%",
                width: 400,
                flexGrow: 1,
              }}
            >
              <Text category="h6" style={{ marginLeft: 4 }}>
                Day {dayIndex + 1}
              </Text>
              <Input
                style={{ margin: 4 }}
                label="Letters"
                value={day.letters}
                onChangeText={(text) =>
                  setDay({
                    ...day,
                    letters: text.toUpperCase().replace(/[^A-Z ]/g, ""),
                  })
                }
              />
              {day.solutions.map((i, index) => (
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Input
                    style={{ margin: 4, flex: 1 }}
                    label={`Solution ${index + 1}`}
                    value={i}
                    onChangeText={(text) =>
                      setDay({
                        ...day,
                        solutions: [
                          ...day.solutions.slice(0, index),
                          text.toUpperCase(),
                          ...day.solutions.slice(index + 1),
                        ],
                      })
                    }
                    accessoryRight={(props) => (
                      <TouchableWithoutFeedback
                        onPress={() =>
                          setDay({
                            ...day,
                            solutions: [
                              ...day.solutions.slice(0, index),
                              ...day.solutions.slice(index + 1),
                            ],
                          })
                        }
                      >
                        <Icon {...props} name="close" />
                      </TouchableWithoutFeedback>
                    )}
                  />
                </View>
              ))}
              <Button
                accessoryLeft={(props) => (
                  <Icon name="plus-outline" {...props} />
                )}
                appearance="ghost"
                status="success"
                onPress={() =>
                  setDay({
                    ...day,
                    solutions: [...day.solutions, ""],
                  })
                }
              >
                Add Solution
              </Button>
              <Datepicker
                accessoryRight={(props) => <Icon name="calendar" {...props} />}
                style={{ margin: 4 }}
                label="Date"
                date={day.date}
                onSelect={(date) =>
                  setDay({
                    ...day,
                    date,
                  })
                }
              />
              {generate.type === "letters_bonus" && <Text style={{ marginLeft: 4 }} category="s1">Bonus Game</Text>}
              {generate.type === "teaser" && <Text style={{ marginLeft: 4 }} category="s1">Teaser</Text>}
              {(generate.type === "teaser" || generate.type === "letters_bonus") && (
                <Input
                  style={{ margin: 4 }}
                  label="Clue"
                  value={day.clue}
                  multiline={true}
                  size="large"
                  onChangeText={(text) =>
                    setDay({
                      ...day,
                      clue: text,
                    })
                  }
                />
              )}
              {generate.type === "letters_bonus" && (
                <Input
                  style={{ margin: 4 }}
                  label="Bonus Solution"
                  value={day.bonus_solution}
                  multiline={true}
                  onChangeText={(text) =>
                    setDay({
                      ...day,
                      bonus_solution: text,
                    })
                  }
                />
              )}
              {generate.days.length > 1 && (
                <Button
                  accessoryLeft={(props) => (
                    <Icon name="close-outline" {...props} />
                  )}
                  appearance="ghost"
                  status="danger"
                  onPress={() =>
                    setGenerate({
                      ...generate,
                      days: [
                        ...generate.days.slice(0, dayIndex),
                        ...generate.days.slice(dayIndex + 1),
                      ],
                    })
                  }
                >
                  Remove Day
                </Button>
              )}
            </Layout>
          );
        })}
      </View>
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        <Button
          style={{ margin: 4, maxWidth: "100%", width: 400, flexGrow: 1 }}
          status="success"
          appearance="ghost"
          accessoryLeft={(props) => <Icon name="plus-outline" {...props} />}
          onPress={() =>
            setGenerate({
              ...generate,
              days: [...generate.days, defaultGenerateDay],
            })
          }
        >
          Add Day
        </Button>
      </View>
      <View style={{ flex: 1 }} />
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        <Button
          style={{ margin: 4, maxWidth: "100%", width: 200, flexGrow: 1 }}
          accessoryLeft={(props) => <Icon name="camera-outline" {...props} />}
          onPress={() => setRenderPreview(!renderPreview)}
        >
          {renderPreview ? "Hide Preview" : "Show Preview"}
        </Button>
        <Button
          style={{ margin: 4, maxWidth: "100%", width: 200, flexGrow: 1 }}
          accessoryLeft={(props) => <Icon name="download-outline" {...props} />}
          onPress={() => setImportMenu(true)}
        >
          Import Data
        </Button>
        <Button
          style={{ margin: 4, maxWidth: "100%", width: 200, flexGrow: 1 }}
          accessoryLeft={(props) => <Icon name="save-outline" {...props} />}
          onPress={() => capture()}
        >
          Save Images
        </Button>
      </View>
    </ScrollView>
  );
}

export default function Editor(
  this: any,
  { generate, setGenerate, capture, design, setDesign, renderPreview, setRenderPreview }: EditorProps
) {
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const shouldLoadComponent = (index: number) => index === selectedIndex;
  return (
    <View style={{ flex: 1 }}>
      <Layout style={{ flex: 1 }}>
        <TabView
          style={{ flex: 1 }}
          selectedIndex={selectedIndex}
          shouldLoadComponent={shouldLoadComponent}
          onSelect={(index) => setSelectedIndex(index)}
        >
          <Tab
            title="Design"
            icon={(props) => <Icon name="brush-outline" {...props} />}
          >
            <DesignEditor design={design} setDesign={setDesign} />
          </Tab>
          <Tab
            title="Generate"
            icon={(props) => <Icon name="edit-outline" {...props} />}
          >
            <GenerateEditor
              renderPreview={renderPreview}
              setRenderPreview={setRenderPreview}
              capture={capture}
              generate={generate}
              setGenerate={setGenerate}
            />
          </Tab>
        </TabView>
      </Layout>
    </View>
  );
}
