const input = `
T: bl
H: Breakfast Letters
D: 2020-11-26
-
L: YOUR LETTERS
S: YOUR SOLUTIONS, COMMA SEPARATED
C: YOUR CLUE
B: YOUR BONUS SOLUTION
-
L: YOUR LETTERS
S: YOUR SOLUTIONS, COMMA SEPARATED
C: YOUR CLUE
B: YOUR BONUS SOLUTION
`

function parse(string: string) {
  const data: { [key: string]: string } = {};
  let key = ``;
  let value = ``;
  for (const line of string.split(/[\n\r]+/g)) {
    if (line.match(/^[a-z_A-Z0-9]+[:-]\s*/)) {
      if (key) data[key.toLowerCase()] = value.trim();
      const match = line.match(/^([a-z_A-Z0-9]+):\s*([^]+)/);
      key = match?.[1] || "";
      value = match?.[2] || "";
    } else {
      value += `\n${line}`;
    }
  }
  if (key) data[key.toLowerCase()] = value;
  return data;
}

function parseType(type: string): GenerateSettings["type"] {
  switch (type) {
    case "letters":
    case "letters_bonus":
    case "teaser":
    case "conundrum":
      return type;
    case "l":
      return "letters";
    case "bl": case "lb": case "bonus":
      return "letters_bonus";
    case "ttt": case "t":
      return "teaser";
    case "c":
      return "conundrum";
    default:
      return defaultGenerateSettings.type;
  }
}

export function ParseGenerate(string: string): GenerateSettings {
  const sections = string.trim().split(/\r?\n\r?-+\r?\n\r?/);
  const settingsString = sections[0];
  const daysStrings = sections.slice(1);
  const settings = parse(settingsString);
  const days = daysStrings.map(i => parse(i));
  const responseData = {
    type: parseType(settings.t?.toLowerCase()),
    title: settings.h || defaultGenerateSettings.title,
    days: days.map((i, index) => ({
      letters: i.l ?? defaultGenerateDay.letters,
      solutions: i.s ? i.s.split(/\s*,\s*/g).map(i=>i.trim()).filter(i=>i) : defaultGenerateDay.solutions,
      clue: i.c ?? defaultGenerateDay.clue,
      bonus_solution: i.b ?? defaultGenerateDay.bonus_solution,
      date: i.d ? new Date(i.d) : (settings.d ? new Date(new Date(settings.d).valueOf() + (index * 86400000)) : new Date())
    }))
  }
  return {
    ...responseData,
    days: responseData.days.length > 0 ? responseData.days : [defaultGenerateDay]
  }
}

export function StringifyGenerate(generate: GenerateSettings) {
  return `TYPE: ${generate.type}
  TITLE: ${generate.title}
  ${generate.days.map(i =>
    `-
    ${i.clue}`
  )}`
}

export const defaultGenerateDay: GenerateDay = {
  letters: "",
  solutions: [""],
  bonus_solution: "",
  clue: "",
  date: new Date(),
}

// export const defaultGenerateDay: GenerateDay = {
//   letters: "ARMPOXRAH",
//   solutions: ["AMPHORA", "AMORPHA"],
//   clue: "Early star of the screen, part of a comedic collective.",
//   bonus_solution: "HARPO MARX",
//   date: new Date(),
// }

export const defaultGenerateSettings: GenerateSettings = {
  title: "",
  type: "letters",
  days: [defaultGenerateDay],
}

export const normalDesignSettings: Partial<DesignSettings> = {
  box_hue: 240,
  box_saturation: 100,
  box_value: 100,
  box_opacity: 70,
  backdrop_hue: 210,
  backdrop_saturation: 100,
  backdrop_value: 100,
  squares_hue: 200,
  squares_saturation: 80,
  squares_value: 90,
  squares_hue_variation: 5,
  squares_saturation_variation: 0,
  squares_value_variation: 10,
}

export const defaultDesignSettings: DesignSettings = {
  credits: "",
  squares: true,
  tile_size: 5,
  grid_size: 50,
  box_hue: 240,
  box_saturation: 100,
  box_value: 100,
  box_opacity: 70,
  backdrop_hue: 210,
  backdrop_saturation: 100,
  backdrop_value: 100,
  squares_hue: 200,
  squares_saturation: 80,
  squares_value: 90,
  squares_hue_variation: 5,
  squares_saturation_variation: 0,
  squares_value_variation: 10,
}

export const halloweenDesignSettings: Partial<DesignSettings> = {
  box_hue: 0,
  box_saturation: 100,
  box_value: 0,
  box_opacity: 90,
  backdrop_hue: 0,
  backdrop_saturation: 0,
  backdrop_value: 0,
  squares_hue: 30,
  squares_saturation: 80,
  squares_value: 90,
  squares_hue_variation: 5,
  squares_saturation_variation: 10,
  squares_value_variation: 10,
}

export const christmasDesignSettings: Partial<DesignSettings> = {
  box_hue: 120,
  box_saturation: 100,
  box_value: 40,
  box_opacity: 100,
  backdrop_hue: 0,
  backdrop_saturation: 80,
  backdrop_value: 100,
  squares_hue: 0,
  squares_saturation: 50,
  squares_value: 100,
  squares_hue_variation: 5,
  squares_saturation_variation: 25,
  squares_value_variation: 0,
}

export type GenerateType = {
  id: "letters" | "letters_bonus" | "teaser" | "conundrum";
  title: string;
  public_title?: string;
}

export const GenerateTypes: GenerateType[] = [
  { id: "letters", title: "Letters" },
  { id: "letters_bonus", title: "Letters w/ Bonus Text", public_title: "Letters" },
  { id: "teaser", title: "Tea Time Teaser" },
  { id: "conundrum", title: "Conundrum" },
]
export type GenerateSettings = {
  title: string;
  type: "letters" | "letters_bonus" | "teaser" | "conundrum";
  days: GenerateDay[];
}
export type GenerateDay = {
  letters: string;
  solutions: string[];
  bonus_solution: string;
  clue: string;
  date: Date;
}
export type DesignSettings = {
  credits: string;
  squares: boolean;
  tile_size: number;
  grid_size: number;
  box_hue: number;
  box_saturation: number;
  box_value: number;
  box_opacity: number;
  backdrop_hue: number;
  backdrop_saturation: number;
  backdrop_value: number;
  squares_hue: number;
  squares_saturation: number;
  squares_value: number;
  squares_hue_variation: number;
  squares_saturation_variation: number;
  squares_value_variation: number;
}