import React from "react";
import Main from "./modules/Main";
import { View, ActivityIndicator, Platform } from "react-native";
import * as eva from "@eva-design/eva";
import { ApplicationProvider, IconRegistry, Layout, Text } from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { useFonts, Rubik_700Bold, Rubik_300Light } from "@expo-google-fonts/rubik";

export default function App() {
  let [fontsLoaded] = useFonts({
    Rubik_700Bold,
    Rubik_300Light,
  });
  return (
    <View style={{ maxWidth: Platform.OS === "web" ? "100vw" : "100%", flex: 1, maxHeight: "100%", overflow: "hidden" }}>
      <IconRegistry icons={EvaIconsPack} />
      <ApplicationProvider {...eva} theme={eva.dark}>
        {fontsLoaded ? <Main /> : <Layout style={{ flex: 1, justifyContent: "center", alignItems: "center" }}><ActivityIndicator color="#ffffff" size={48} /><Text style={{ fontSize: 24 }}>Loading</Text></Layout>}
      </ApplicationProvider>
    </View>
  );
}
